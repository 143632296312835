import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Modal, Input,Spinner } from "reactstrap"
import {Helmet} from "react-helmet";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { capitalize } from "lodash"
import axios from "axios";
import AppConfig from "constants/config";
import ButtonDropdown from "../Lease/ButtonDropdown"
import toast from 'react-hot-toast';
import MultiSelect from "components/Common/MultiSelect";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'
import Layout from "components/VerticalLayout";

const RandomSampling = () => {
  const [randomData, setRandomData] = useState([]);
  const [sampleId, setSampleId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingRandom, setLoadingRandom] = useState(true);
  const [scoreOpen, setScoreOpen] = useState(false);
  const [lease,setLease] = useState({})
  const [cases,setCases] = useState([])
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [interval, setInt] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedCustomers,setSelectedCustomers] = useState([]);
  const [options, setOptions] = useState([]);
  const [limit, setLimit] = useState('');
  const [script, setRunScript] = useState(false);
  const [leaseType, setLeaseType] = useState('daily')
  const columns = [
    {
      name: "client_id.customer_id.company",
      label: "Account",
      options:{
        sort : false,
        search: true,
        setCellProps: () => ({style: {minWidth: '200px',height: '70px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
        customBodyRenderLite: (dataIndex) => (
          <div className="table-data-margin">
            <div>{ randomData && randomData[dataIndex] && randomData[dataIndex].client_id && randomData[dataIndex].client_id.customer_id && randomData[dataIndex].client_id.customer_id.company ? randomData[dataIndex].client_id.customer_id.company : ''}</div>
          </div>
        ),
      }
    },
    {
      name: "client_id.client",
      label: "Client",
      options:{
        sort : false,
        search: true,
        setCellProps: () => ({style: {minWidth: '200px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
        customBodyRenderLite: (dataIndex) => (
          <>
            {randomData && (!randomData[dataIndex] || !randomData[dataIndex].client_id || !randomData[dataIndex].client_id.logo) ? (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="avatar-sm">
                  <div className="avatar-title rounded-circle">
                    { randomData && randomData[dataIndex] && randomData[dataIndex].client_id && randomData[dataIndex].client_id.client ? randomData[dataIndex].client_id.client.charAt(0) : ''}
                  </div>
                </div>
                <div className="client">
                  <div>{ randomData && randomData[dataIndex] && randomData[dataIndex].client_id && randomData[dataIndex].client_id.client ? randomData[dataIndex].client_id.client : ''}</div>
                </div>
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="avatar-sm">
                  <img
                    className="rounded-circle avatar-sm"
                    src={ randomData && randomData[dataIndex] && randomData[dataIndex].client_id && randomData[dataIndex].client_id.logo ? randomData[dataIndex].client_id.logo : ''}
                    alt=""
                  />
                </div>
                <div className="client">
                  <div>{ randomData && randomData[dataIndex] && randomData[dataIndex].client_id && randomData[dataIndex].client_id.client ? randomData[dataIndex].client_id.client : ''}</div>
                </div>
              </div>
            )}
          </>
        ),
      }
    },
    {
      label: "Lease name",
      name: "name",
      options:{
        sort : true,
        search: true,
        setCellProps: () => ({style: {minWidth: '250px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
        customBodyRenderLite: (dataIndex) => (
          <>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="avatar-sm">
                  <button
                    type="button"
                    className="btn rounded-circle avatar-sm bg-theme-color mt-0 ms-0 text-align-center"
                  >
                    {randomData && randomData[dataIndex] && randomData[dataIndex].type === 'Headquarters' ? <i className='bx bx-buildings text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                      : randomData && randomData[dataIndex] && randomData[dataIndex].type === 'Super Car' ? <i className='bx bxs-car text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                      : randomData && randomData[dataIndex] && randomData[dataIndex].type === 'Trade Center' ? <i className='bx bxs-building-house text-white' style={{ fontSize: '150%', padding: '0' }}></i> 
                      : randomData && randomData[dataIndex] && randomData[dataIndex].type === 'Vehicle' ? <i className='bx bxs-car text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                      : ((randomData && randomData[dataIndex] && randomData[dataIndex].type === 'Building') || (randomData && randomData[dataIndex] && randomData[dataIndex].type === 'Office')) ? <i className='bx bxs-buildings text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                      : randomData && randomData[dataIndex] && randomData[dataIndex].type === 'Land' ? <i className='bx bxs-landscape text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                      : randomData && randomData[dataIndex] && randomData[dataIndex].type === 'Equipment' ? <i className='bx bx-list-ol text-white' style={{ fontSize: '180%', padding: '0' }}></i>
                      : randomData && randomData[dataIndex] && randomData[dataIndex].type === 'Others' ? <i className='bx bxs-customize text-white' style={{ fontSize: '150%', padding: '0' }}></i> : ''
                    }
                  </button>
                </div>
                <div className="client">
                  <div>{(randomData && randomData[dataIndex] && randomData[dataIndex].name).toUpperCase()}</div>
                </div>
              </div>
          </>
        ),
      }
    },
    {
      name: "audit_score",
      label: "Audit score",
      options:{
        sort : false,
        search: true,
        setCellProps: () => ({style: {minWidth: '100px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
        customBodyRenderLite: (dataIndex) => (
          <div className={`table-data-margin ${ randomData && randomData[dataIndex] && randomData[dataIndex].status == 'Terminated' && randomData && randomData[dataIndex] && randomData[dataIndex].audit_score == '7/8' ? 'text-success' : randomData && randomData[dataIndex] && randomData[dataIndex].audit_score == '8/8' ? 'text-success' : randomData && randomData[dataIndex] && randomData[dataIndex].status == 'Terminated' && randomData && randomData[dataIndex] && randomData[dataIndex].audit_score == '10/11' ? 'text-success' : randomData && randomData[dataIndex] && randomData[dataIndex].audit_score == '11/11' ? 'text-success' : 'text-danger'}`}>
            <div>{randomData && randomData[dataIndex] && randomData[dataIndex].audit_score}</div>
          </div>
        ),
      }
    },
    {
      name: "classification",
      label: "Classification",
      options:{
        sort : true,
        search: true,
        customBodyRender: (lease) => (
          <div className="table-data-margin">
            <div>{lease == 'operating' ? 'Operating' : 'Financing'}</div>
          </div>
        ),
      }
    },
    {
      name: "reporting_standard",
      label: "Reporting Standard",
      options:{
        sort : false,
        search: true,
        setCellProps: () => ({style: {minWidth: '150px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
        customBodyRender: (lease) => (
          <div className="table-data-margin">
            <div>{lease ?  lease : "FASB ASC 842"}</div>
          </div>
        ),
      }
    },
    {
      name: "calculation_type",
      label: "Lease Type",
      options:{
        sort : false,
        search: true,
        setCellProps: () => ({style: {minWidth: '100px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
        customBodyRender: (lease) => (
          <div className="table-data-margin">
            <div>{lease ? capitalize(lease) : 'Daily'}</div>
          </div>
        ),
      }
    },
    {
      name: "status",
      label: "Status",
      options:{
        sort : true,
        search: true,
        customBodyRender: (lease) => (
          <div className="table-data-margin">
            <div>{lease}</div>
          </div>
        ),
      }
    },
    {
      name: "menu",
      label: 'Actions',
      options:{
        customBodyRenderLite: (dataIndex) => (
          <div className="mt-2">
            {
              randomData && randomData[dataIndex] && !randomData[dataIndex].is_processing && <ButtonDropdown lease={randomData && randomData[dataIndex]} setScoreOpen={setScoreOpen} setLease={setLease}/>
            }          
          </div>
        ),
      }
    },
  ];

  const toggleScore = () => {
      setScoreOpen(!scoreOpen);
      setLease({})      
  }

  useEffect(()=>{
    setCases(Object.keys(lease.audit_result || {}))
  },[lease])

  useEffect(() => {
      getCustomer()
      getRandomSample('','refresh')
      setLimit()
  }, [])

  const createRandomSample = async(data) => {
    setLoading(true)
    try {
      let {data: value} = await axios.post(`${AppConfig.baseUrl}/super_admin/createRandomSample`,data, {
        headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
        }
      })
      if(value && !value.error){
        getRandomSample(value && value.newSamples && value.newSamples._id ? value.newSamples._id :'')        
      }else{
        throw new Error(value.title)
      }
    } catch(err){
      setLoading(false)
      toast.error( err.message || 'Something went wrong');
      console.log('error in create sample ->',err)
    }   
  }

  const getRandomSample = async(id,type) => {
    setLoadingRandom(true)
    setRandomData([])
    try{
          let {data: value} = await axios.get(`${AppConfig.baseUrl}/super_admin/getRandomSample?id=${id ? id : ''}`, {
          headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
          }
          })
          if(value && !value.error){
            if(type != 'refresh'){
              toast.success('Leases fetched successfully');
            }
            setRandomData(value.leases)
            setSampleId(value.id)
            setIsProcessing(value.is_processing)            
          }else{
            throw new Error(value.title)
          }
      } catch(err){
        if(type != 'refresh'){
          toast.error( err.message || 'Something went wrong');
        }
        console.log('error in get random sample ->',err)
      }   
      setLoadingRandom(false)
  }

  useEffect(() => {
    if(isProcessing){
      let int = setInterval(() => {
        getRandomSample(sampleId,'refresh')
      }, 3000)
      setInt(int)
    }
    else{
      clearInterval(interval)
    }
  }, [isProcessing])

  const runScript = async(id) => {
    setLoading(true)
    try {
      let {data:value} = await axios.get(`${AppConfig.baseUrl}/super_admin/runScript?id=${id}&&type=${leaseType}`, {
        headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
        }
      })
      if(value && !value.error){
        toast.success('Script started successfully');
        handleClose()
        getRandomSample(id,'refresh')
        setIsProcessing(true)
      }else{
        throw new Error(value.title)
      }
    } catch(err){
      toast.error( err.message || 'Something went wrong');
      console.log('error in run script ->',err)
    }   
    setLoading(false)
  }

  const deleteSample = async() => {
    setLoading(true)
    try {
      const {data:value} = await axios.get(`${AppConfig.baseUrl}/super_admin/deleteSample?id=${sampleId}`, {
        headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
        }
      })
      if(value && !value.error){
        toast.success('Leases cleared successfully');
        handleClosePopup()
        setRandomData([])
      }else{
        throw new Error(value.title)
      }
    } catch(err){
      toast.error( err.message || 'Something went wrong');
      console.log('error in delete sample ->',err)
    }   
    setLoading(false)
  }

  const getCustomer = async() => {
    setLoading(true)
    setOptions([])
    try {
      let {data:value} = await axios.get(`${AppConfig.baseUrl}/customer/listing`,{
        headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
        }
      })
      if(value && !value.error){
        let customers = [];
        customers.push({label: 'Select All', value: 'selectall'})
        for(const d of value.custData){
          if(d && d._id){
              customers.push({label: d.customer_id.company, value: d.customer_id._id})
          }
        }
        setOptions(customers)
      }else{
        throw new Error(value.title)
      }      
    } catch(err){
      console.log('error in customer listing ->',err)
    }   
    setLoading(false)
  }
  
  const handleChange = (selected) =>{
    let oldSelect = selectedCustomers.filter(v => v.value == 'selectall' )
    let newSelect = selected.filter(v => v.value == 'selectall' )
    if(selected.length != options.length && newSelect && newSelect[0] && selected.length > 1){
      let select = selected.filter(v => v.value != 'selectall')
      setSelectedCustomers(select)
    }else if(newSelect && newSelect[0]){
      setSelectedCustomers(options)
    }else if(oldSelect && oldSelect[0] && (!newSelect || !newSelect[0])){
      setSelectedCustomers([])
    }else if(selected.length == options.length - 1 && (!newSelect || !newSelect[0])){
      setSelectedCustomers(options)
    }else{
      setSelectedCustomers(selected)
    }
  }

  const refreshLeaseData = (check) => {
      const customers = selectedCustomers.filter(v => v.value != 'selectall' )
      createRandomSample({customers: customers,limit:limit})
  }
  const toggleDelete = () => {
    setDeleteOpen(!deleteOpen);
  }

  const handleClosePopup = () => {
    setDeleteOpen(false);
  }

  const toggleRun = () => {
    setRunScript(!script);
  }

  const handleClose = () => {
    setRunScript(false);
  }

  const handleChangeType = (e) => {
    setLeaseType(e.target.value)
  }

  return (
    <Layout>
      <div className="page-content">
       <Helmet>
          <title>LeaseJava | Random Sample</title>
       </Helmet>
        <div className="container-fluid">
          <Breadcrumbs title="lease " breadcrumbItem="Random Sample"/>
          <Row>
            <Col className="col-12">
                <Card>
                    <CardBody>
                      <ThemeProvider theme={createTheme({components: {...getMuiTheme()}})}>
                      <MUIDataTable
                          title={
                            <Row className="row">
                                <Col md='3'>
                                    <MultiSelect options={options} handleChange={handleChange} selectedOptions={selectedCustomers} loading={loading} placeholder="Select accounts ..." />
                                </Col>
                                <Col md="3">
                                  <div>
                                      <Input
                                          type="number"
                                          className="form-control"
                                          id="limit"
                                          disabled={loading}
                                          value={limit}
                                          onChange={(e) =>{
                                              setLimit(e.target.value)
                                          }}
                                      />
                                  </div>
                                </Col>
                                <Col md='6'>
                                    <div className="d-flex">
                                        <div className="me-4">
                                            <button className="btn bg-theme-color" onClick={() => refreshLeaseData(limit)} disabled={loading || isProcessing || !selectedCustomers || (selectedCustomers &&  selectedCustomers.length < 1)} >
                                                {
                                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                                }
                                                Fetch Leases
                                            </button>
                                        </div>
                                        <div className="me-4">
                                            <button className="btn bg-theme-color" onClick={() => setRunScript(true)} disabled={loading || isProcessing || !randomData || (randomData && randomData.length < 1)} >
                                                {
                                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                                }
                                                Run Script
                                            </button>
                                        </div>
                                        <div className="me-4">
                                            <button className="btn bg-theme-color" onClick={() => setDeleteOpen(true)} disabled={loading ||  isProcessing || !randomData ||(randomData && randomData.length < 1)} >
                                                {
                                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                                }
                                                Clear Leases
                                            </button>
                                        </div>
                                    </div>
                                </Col>                                 
                            </Row>
                          }
                          data={randomData}
                          columns={columns}
                          options={{ 
                          rowsPerPage:10, 
                          print:false, 
                          download:false, 
                          filter:false, 
                          viewColumns:false,
                          pagination : false,
                          selectableRows:"none", 
                          search:false, 
                          rowsPerPageOptions:[], 
                          responsive:'scroll',
                          textLabels: {
                            body: {
                              noMatch: loadingRandom ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                            },
                            viewColumns: {
                            title: "",
                            }
                          },
                          setRowProps: (row, dataIndex, rowIndex) => {
                            return {
                              style: { backgroundColor: randomData[rowIndex].is_processing ? '#faf1d7' : '#fff'},
                            };
                          },
                          }}
                      />
                      </ThemeProvider>
                    </CardBody>
                </Card>              
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        isOpen={scoreOpen}
        toggle={() => {
            toggleScore();
        }}
        centered
      >
        <div className="modal-header">
            <h5 className="modal-title mt-0">Audit score</h5>
            <button
                type="button"
                onClick={() => {
                  toggleScore();
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
          <ul className="verti-timeline list-unstyled">
            {
              Array.from(Array(Math.ceil(cases.length/2)).keys()).map((v,i)=>
              { 
                return(
                  <Row key={i}>
                    {
                    cases[i*2+0] ?
                      <Col>
                        <li className="event-list">
                          <div className="event-timeline-dot me-3">                            
                            { lease.status == 'Terminated' && i*2+0 == 0 ? <i className='bx bx-minus-circle text-secondary font-size-22'></i> : lease.audit_result && lease.audit_result[cases[i*2+0]] ? <i className='bx bx-check-circle text-success font-size-22'></i> : <i className='bx bx-x-circle text-danger font-size-22'></i>}
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h5>{cases[i*2+0]}</h5>
                              </div>
                            </div>
                          </div>
                        </li>
                      </Col>
                    : ''
                    }
                    {
                    cases[i*2+1] ?
                      <Col>
                        <li className="event-list">
                          <div className="event-timeline-dot me-3">
                            {lease.audit_result && lease.audit_result[cases[i*2+1]] ? <i className='bx bx-check-circle text-success font-size-22'></i>:<i className='bx bx-x-circle text-danger font-size-22'></i>}
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h5>{cases[i*2+1]}</h5>
                              </div>
                            </div>
                          </div>
                        </li>
                      </Col>
                    : ''
                    }
                  </Row>
              )
              })
            }
            
          </ul>
        </div>
      </Modal>
      <Modal
          isOpen={deleteOpen}
          toggle={() => {
              toggleDelete();
          }}
          centered
      >
          <div className="modal-header">
              <h5 className="modal-title mt-0">Clear Leases</h5>
              <button
                  type="button"
                  onClick={() => {
                      handleClosePopup()
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
              >
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div className="modal-body">
              <form>
                  <div className="mb-3">
                      Are you sure you want to clear leases ?
                  </div>
                  <div className="modal-footer mt-0">
                      <button
                          type="button"
                          className="btn bg-theme-color"
                          onClick={() =>
                              handleClosePopup()
                          }
                          disabled={loading}
                      >
                          {
                              loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                          }
                          No
                      </button>
                      <button type="button" className="btn bg-theme-color" onClick={deleteSample} disabled={loading}>
                          {
                              loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                          }
                          Yes
                      </button>
                  </div>
              </form>
          </div>
      </Modal>
      <Modal
          isOpen={script}
          toggle={() => {
              toggleRun();
          }}
          centered
      >
          <div className="modal-header">
              <h5 className="modal-title mt-0">Switch Leases</h5>
              <button
                  type="button"
                  onClick={() => {
                      handleClose()
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
              >
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div className="modal-body">
              <form>
                  <div className="row mb-3 ms-1" style={{fontSize:"15px"}}>
                      Switch all leases to
                        <select
                        value={leaseType}
                        onChange={handleChangeType}
                        id="formrow-InputState"
                        className="ms-4 form-control form-control-sm arrows"
                        style={{width:"100px"}}
                        >
                        <option name="daily" value="daily">Daily</option>
                        <option name="monthly" value="monthly">Monthly</option>
                        </select>
                  </div>
                  <div className="modal-footer mt-0">
                      <button
                          type="button"
                          className="btn bg-theme-color"
                          onClick={() =>
                              handleClose()
                          }
                          disabled={loading}
                      >
                          {
                              loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                          }
                          Cancel
                      </button>
                      <button type="button" className="btn bg-theme-color" onClick={() =>runScript(sampleId)} disabled={loading}>
                          {
                              loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                          }
                          Confirm
                      </button>
                  </div>
              </form>
          </div>
      </Modal>
    </Layout>    
    )
}
export default RandomSampling